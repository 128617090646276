import { Button, Divider, Typography } from "antd";

import { AppleIcon, GoogleIcon } from "./ProviderIcons";

interface Props {
  loading: boolean;
}

const Text = Typography.Text;

const ExtraLoginOptions = ({ loading }: Props) => {
  return (
    <section className="flex flex-col w-full">
      <div className="w-full relative flex items-center justify-center">
        <Divider className="w-full" />
        <Text className="absolute " type="secondary" style={{ zIndex: 1 }}>
          OR
        </Text>
      </div>

      <div className="pt-3 pb-2 w-full">
        <Button size="large" className="w-full" icon={<GoogleIcon />}>
          Sign in with Google
        </Button>
      </div>
      <div className="pt-2 pb-3 w-full">
        <Button size="large" className="w-full" icon={<AppleIcon />}>
          Sign in with Apple
        </Button>
      </div>
    </section>
  );
};

export default ExtraLoginOptions;
